import { StrictMode } from "react";
import ReactDOM from "react-dom";
import {Helmet} from "react-helmet";

import App from "./App";

const rootElement = document.getElementById("root");
ReactDOM.render(
  <StrictMode>
    <Helmet>
      <title>Matteo Albini: coming soon</title>
      <meta name="description" content="Matteo Albini: coming soon" />
    </Helmet>
    <App />
  </StrictMode>,
  rootElement
);
