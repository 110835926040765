import React from "react";
import "./styles.css";

/**
 * https://stackoverflow.com/questions/65840192/loop-through-an-array-and-show-single-item-at-a-time-with-dynamic-time-duration
 */

const items = [
  { name: "coming soon" },
  { name: "prossimamente" },
  { name: "곧 출시" },
  { name: "již brzy" },
  { name: "قريبا" },
  { name: "próximamente" },
  { name: "近日公開" },
  { name: "язык простака" },
  { name: "即将推出" },
  { name: "בקרוב" },
];

export default function App() {
  const [mediaItem, setMediaItem] = React.useState(items[0]);
  const [index, setIndex] = React.useState(0);

  React.useEffect(() => {
    const timerId = setInterval(
      () => setIndex((i) => (i + 1) % items.length),
      800
    );
    return () => clearInterval(timerId);
  }, []);

  React.useEffect(() => {
    setMediaItem(items[index]);
  }, [index]);

  return (
    <div className="App">
      <h1 id="title">{mediaItem.name}</h1>
    </div>
  );
}
